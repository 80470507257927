(function() {
    'use strict';

    angular
        .module('onefacture')
        .controller('LicencesToPdf', LicencesToPdf);

    LicencesToPdf.$inject = ['$scope', '$http', '$timeout', '$window'];

    function LicencesToPdf($scope, $http, $timeout, $window) {

        $scope.licencesLoaded = false;
        $scope.licences = [];

        function getBase64Image(imgUrl, callback) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function() {
                var canvas = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.naturalHeight;
                canvas.width = this.naturalWidth;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL('image/png');
                callback(dataURL);
            };
            img.src = imgUrl;
        }

        $scope.downloadLicences = () => {
            getBase64Image('/images/logo.png', function(logoBase64) {
                const { jsPDF } = window.jspdf;
                const doc = new jsPDF('landscape');
                const pageWidth = doc.internal.pageSize.width;
                const pageHeight = doc.internal.pageSize.height;
                // Calcular las posiciones y dimensiones del rectángulo blanco
                const topMargin = pageHeight * 0.25;
                const bottomMargin = pageHeight * 0.25;
                const sideMargin = pageWidth * 0.25;
                const rectX = sideMargin / 2;
                const rectY = topMargin;
                const rectWidth = pageWidth - sideMargin;
                const rectHeight = pageHeight - topMargin - bottomMargin;
                // Agregar fondo gris como wrapper
                doc.setFillColor(230);
                doc.rect(0, 0, pageWidth, pageHeight, 'F');
                // Agregar rectángulo blanco como wrapper para el contenido específico
                doc.setFillColor(255);
                doc.rect(rectX, rectY, rectWidth, rectHeight, 'F');
                // Agregar logo
                doc.addImage(logoBase64, 'PNG', 47, 33, 20, 15);
                // Agregar título grande
                doc.setFontSize(32);
                doc.setFont('helvetica', 'bold');
                doc.text("onefacture", 70, 45);
                 // Espacio después del título
                doc.setFontSize(18);
                doc.setFont('helvetica', 'bold');
                const subtitleText = $scope.licences.length == 1 ? "Licencia Profesional 365:" : "Licencias Profesionales 365:";
                const subtitleWidth = doc.getStringUnitWidth(subtitleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const subtitleX = (doc.internal.pageSize.width - subtitleWidth) / 2;
                doc.text(subtitleText, subtitleX, 70);
                // Listar las licencias centradas
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                const columnWidth = rectWidth / 2;
                const startY = rectY + 30;
                const rowHeight = 8;
                let lastY = startY;
                let nextSectionY ;

                if ($scope.licences.length <= 4) {
                     doc.setFontSize(14);
                    const licence = $scope.licences[0];
                    const licenceWidth = doc.getStringUnitWidth(licence) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                    const licenceX = (pageWidth - licenceWidth) / 2;
                    doc.text(licence, licenceX, startY + 20);
                    lastY = startY + rowHeight;
                    nextSectionY = lastY + (pageHeight - lastY) * 0.35;

                } else {
                    $scope.licences.forEach((licence, index) => {
                        const col = index % 2;
                        const row = Math.floor(index / 2);
                        const x = rectX + col * columnWidth + (columnWidth - doc.getStringUnitWidth(licence) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2;
                        const y = startY + row * rowHeight;
                        doc.text(licence, x, y);
                        lastY = y + rowHeight;
                        nextSectionY = lastY + (pageHeight - lastY) * 0.10;
                    });
                }
                // Agregar instrucciones
                doc.setFontSize(12);
                const instructionsText = "¿Cómo activo mi licencia?";
                const instructionsWidth = doc.getStringUnitWidth(instructionsText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const instructionsX = (doc.internal.pageSize.width - instructionsWidth) / 2;
                doc.text(instructionsText, instructionsX,  nextSectionY);
                doc.setFontSize(10);
                const linkText = "Activa tu licencia aquí";
                const linkWidth = doc.getStringUnitWidth(linkText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const linkX = (doc.internal.pageSize.width - linkWidth) / 2;
                doc.setTextColor(0, 0, 255);
                doc.textWithLink(linkText, linkX, nextSectionY + 5, { url: "https://onefacture.com/blog/activar-licencia-onefacture-cfdi" });
                // Agregar vigencia
                doc.setTextColor(0, 0, 0);
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                const validityText = "Vigencia de 365 días partir de la activación.";
                const validityWidth = doc.getStringUnitWidth(validityText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const validityX = (doc.internal.pageSize.width - validityWidth) / 2;
                doc.text(validityText, validityX, nextSectionY + 13);
                // Agregar contacto
                doc.setFontSize(8);
                const contactText1 = "¿Tienes dudas?";
                const contactText2Part1 = "Contáctanos en contacto@onefacture.com o a través";
                const contactText2Part2 = "de nuestra página de Facebook.";
                const contactWidth1 = doc.getStringUnitWidth(contactText1) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const contactWidth2Part1 = doc.getStringUnitWidth(contactText2Part1) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const contactWidth2Part2 = doc.getStringUnitWidth(contactText2Part2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const contactX1 = (pageWidth - contactWidth1) / 2;
                const contactX2Part1 = (pageWidth - (contactWidth2Part1 + contactWidth2Part2)) / 2;
                const contactX2Part2 = contactX2Part1 + contactWidth2Part1;
                doc.setFont('helvetica', 'bold');
                doc.text(contactText1, contactX1, nextSectionY + 18);
                doc.setFont('helvetica', 'normal');
                doc.text(contactText2Part1, contactX2Part1, nextSectionY + 23);
                doc.setTextColor(0, 0, 255);
                doc.textWithLink(contactText2Part2, contactX2Part2, nextSectionY + 23, { url: "https://www.facebook.com/onefacture" });
                doc.setTextColor(0, 0, 0);
                // Texto de derechos
                const currentYear = new Date().getFullYear();
                const copyrightTextPart1 = `\u00A9 ${currentYear} `;
                const copyrightTextPart2 = "onefacture";
                const copyrightTextPart3 = " - Todos los derechos reservados.";
                const copyrightWidthPart1 = doc.getStringUnitWidth(copyrightTextPart1) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const copyrightWidthPart2 = doc.getStringUnitWidth(copyrightTextPart2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const copyrightWidthPart3 = doc.getStringUnitWidth(copyrightTextPart3) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const totalCopyrightWidth = copyrightWidthPart1 + copyrightWidthPart2 + copyrightWidthPart3;
                const copyrightX = (pageWidth - totalCopyrightWidth) / 2;
                const copyrightY = rectY + rectHeight + 10;
                doc.text(copyrightTextPart1, copyrightX, copyrightY);
                doc.setTextColor(0, 0, 255);
                doc.textWithLink(copyrightTextPart2, copyrightX + copyrightWidthPart1, copyrightY, { url: "https://www.onefacture.com" });
                doc.setTextColor(0, 0, 0);
                doc.text(copyrightTextPart3, copyrightX + copyrightWidthPart1 + copyrightWidthPart2, copyrightY);
                // Guardar el PDF
                doc.save('licencias onefacture.pdf');
            });
        };

        const urlParams = new URLSearchParams(window.location.search);
        const transaction_id = urlParams.get('transaction_id');

        if (transaction_id) {
            $http.get(`/api/v1/payments/getCodesByTransaction/${transaction_id}`)
                .then((response) => {
                    const licences = response.data.codes;
                    const $container = $('#licences-container');
                    if (licences && licences.length > 0) {
                        let list = '<ul style="display: inline-block; padding: 0; margin: 0;">';
                        licences.forEach((licence) => {
                            list += `<li style="padding: 0; margin: 5px;">${licence}</li>`;
                        });
                        list += '</ul>';
                     
                        $container.html(list);
                        $scope.$applyAsync(() => {
                            $scope.licences = licences;
                            $timeout(() => {
                                $scope.licencesLoaded = true;
                                console.log('Licences Loaded:', $scope.licencesLoaded);
                            });
                        });
                    } else {
                        $container.html('<p>No se encontraron licencias.</p>');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    $('#licences-container').html('<p>Error al cargar las licencias. Por favor, inténtalo de nuevo más tarde.</p>');
                });
        }
    }

})();
