angular.module('onefacture', [
	'toastr'
]);
firebase.initializeApp({
apiKey: "AIzaSyBxdGZQtIf9iD7vtWB8vE0RJp0ya9rXAv0",
authDomain: "onefacture.firebaseapp.com",
databaseURL: "https://onefacture.firebaseio.com",
projectId: "onefacture",
storageBucket: "onefacture.appspot.com",
messagingSenderId: "804594886280"
});

firebase.auth().onAuthStateChanged(function(user) {
  if (!user) {
    window.location.href = '/iniciar-sesion';
  }
});
