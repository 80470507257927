(function() {
    'use strict';

    angular
        .module('onefacture')
        .controller('SessionController', SessionController);

    SessionController.$inject = ['$scope', '$http', '$timeout', '$window'];

    function SessionController($scope, $http, $timeout, $window) {

        $scope.closeSession = () => {
            console.log('closeSession');
            firebase.auth().signOut();
            $window.location.href = '/iniciar-sesion';
        };

        $scope.returnPay = () =>{
            $window.location.href = '/pay';
        };

    }

})();
